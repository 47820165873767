import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";

const BookNowModel = (props) => {
  
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.handleShow(false)}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Booking Enquiry Information &nbsp; <span className={`btn btn-sm rounded-pill  ${props.modeldata.status == 'Approved' ? "btn-primary" : "btn-danger"}`}>{props.modeldata.status}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p></p>

        <ul>
          <li> Full Name : &nbsp; {props.modeldata.full_name}</li>
          <li> Email  : &nbsp; {props.modeldata.email}</li>
          <li> Phone  : &nbsp; {props.modeldata.phone}</li>
          <li>Printer Brand Name : &nbsp; {props.modeldata.printer_brand_name}</li>
          <li>Printer Connected With : &nbsp; {props.modeldata.printer_connected_with}</li>
          <li>Printer Model Number : &nbsp; {props.modeldata.printer_model_number}</li>
          <li>Under Warrenty : &nbsp;  {props.modeldata.under_warrenty}</li>
          <li>City : &nbsp;  {props.modeldata.city}</li>
          <li>State : &nbsp;  {props.modeldata.state}</li>
          <li>Zip Code : &nbsp;  {props.modeldata.zip}</li>
          <li>Home Address : &nbsp; {props.modeldata.home_address}</li>
          <li>Booking Date : {moment(props.modeldata.created).format('MMMM Do YYYY, h:mm:ss a')}</li>
          <li>Description : &nbsp;  {props.modeldata.description}</li>
          
        
        </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.handleShow(false)}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
    </>
  );


}

export default BookNowModel;
