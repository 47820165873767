import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

const TermsConditions = ({metadescription}) => {
  const[storematatag,setStorematatag] = useState("");
  useEffect(()=>{
   const targetSlug = 'terms-and-condition';
   const targetObject = metadescription.find(item => item.slug === targetSlug);
   setStorematatag(targetObject)
  },[metadescription])

  return (
    <>
    {storematatag  &&
      <Helmet>
        <meta name="description" content={storematatag.meta_key_discription} />
        <meta name="keywords" content={storematatag.meta_keyword} />
      </Helmet>}
      {/* <section className="privecy_policy mt-5">
        <div className="container">
          <h1 className="heading_sub_page_for_custome">
            Terms &amp; Conditions
          </h1>
          <div className="privecy_policy_div">
            <h3 className="mt-1 mb-3 main_heading_bar_privecy_policy">
              Acceptance of Terms:
            </h3>

            <p>Welcome to shipparcel.ca!</p>

            <p>
              These terms and conditions outline the rules and regulations for
              the use of shipparcel's Website, shipparcel.ca
              
            </p>

            <p>
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use shipparcel.ca if you do not
              agree to take all of the terms and conditions stated on this page.
            </p>

            <p>
              By using our parcel shipping services, you agree to abide by these
              terms and conditions. Please read them carefully.
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              Parcel Packaging:
            </h3>

            <p>
              You are responsible for ensuring that your parcel is properly
              packaged to withstand normal handling during transit.
            </p>

            <p>
              We are not liable for any damage to improperly packaged parcels.
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              Shipment Restrictions:
            </h3>

            <p>
              You must comply with all local, national, and international
              shipping regulations.
            </p>
            <p>
              We do not ship hazardous materials, prohibited items, or items of
              high value. Please refer to our shipping guidelines for more
              information.
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              Pickup and Delivery:
            </h3>
            <ul>
              <li>
                We will make all reasonable efforts to pick up and deliver
                parcels as scheduled.
              </li>
              <li>
                Delays may occur due to circumstances beyond our control, such
                as weather, traffic, or customs inspections.
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">
              Liability and Insurance:
            </h3>

            <ul>
              <li>
                Our liability for lost or damaged parcels is limited to the
                declared value or the actual value, whichever is lower.
              </li>
              <li>
                You may purchase additional insurance for high-value parcels.
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">Claims:</h3>

            <ul>
              <li>
                All claims for lost or damaged parcels must be submitted in
                writing within [number] days of the delivery date.
              </li>
              <li>
                We may require supporting documentation to process claims.
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">
              Delivery Verification:
            </h3>

            <ul>
              <li>
                We will provide a delivery confirmation for each parcel,
                including the date and time of delivery.
              </li>
              <li>
                This confirmation is sufficient evidence of successful delivery.
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">Payment:</h3>
            <ul>
              <li>You are responsible for all shipping charges.</li>
              <li>
                Payment terms are [insert your payment terms, e.g., "prepaid,"
                "collect," "cash on delivery," etc.].
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">
              Cancellation and Refunds:
            </h3>
            <p>
              Cancellation and refund policies vary depending on the specific
              shipping service selected. Please refer to our website or contact
              our customer service for details.
            </p>

            <h3 className="main_heading_bar_privecy_policy">Governing Law:</h3>
            <p>
              These terms and conditions are governed by the laws of [your
              jurisdiction].
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              Contact Information:
            </h3>

            <ul>
              <li>
                For any questions or concerns regarding our parcel shipping
                services, please contact our customer service at [customer
                service contact information].
              </li>
            </ul>
            <p>
              Please note that this is a general template, and you should
              customize it to meet the specific needs of your shipping business.
              It's also important to have legal counsel review and help you
              finalize your terms and conditions to ensure they are legally
              sound and compliant with relevant regulations in your
              jurisdiction.
            </p>

          </div>
        </div>
      </section> */}
      <section className="privecy_policy mt-5">
  <div className="container">
    <h1 className="heading_sub_page_for_custome">Terms &amp; Conditions</h1>
    <div className="terms_conditions_div">
      <h3 className="mt-1 mb-3 main_heading_bar_terms_conditions">Acceptance of Terms:</h3>
      <p>Welcome to our Printer Repair Services!</p>
      <p>These terms and conditions outline the rules and regulations for the use of our Printer Repair Services.</p>
      <p>By accessing our services, we assume you accept these terms and conditions. Do not continue to use our services if you do not agree to all of the terms and conditions stated on this page.</p>

      <h3 className="main_heading_bar_terms_conditions">Service Agreement:</h3>
      <p>By using our printer repair services, you agree to abide by these terms and conditions. Please read them carefully.</p>

      <h3 className="main_heading_bar_terms_conditions">Repair Authorization:</h3>
      <p>You authorize our technicians to perform repair and maintenance services on your printer. You confirm that you are the owner of the printer or have the owner's permission to authorize repairs.</p>

      <h3 className="main_heading_bar_terms_conditions">Service Limitations:</h3>
      <p>We provide repair services for most printer brands and models. However, some issues may be beyond our capability to repair, and we will inform you if we cannot complete the repair.</p>

      <h3 className="main_heading_bar_terms_conditions">Estimates and Payments:</h3>
      <ul>
        <li>We provide estimates for repair costs before starting any work. These estimates are subject to change if additional issues are discovered during the repair process.</li>
        <li>Payment for services is due upon completion of the repair. We accept [insert payment methods, e.g., cash, credit card, etc.].</li>
      </ul>

      <h3 className="main_heading_bar_terms_conditions">Warranty and Liability:</h3>
      <ul>
        <li>We offer a [insert warranty period, e.g., 30-day] warranty on all repairs. This warranty covers parts and labor for issues directly related to the repair performed.</li>
        <li>We are not liable for any damage to the printer that occurs before or after the repair service.</li>
      </ul>

      <h3 className="main_heading_bar_terms_conditions">Data Protection:</h3>
      <p>We recommend backing up all important data before bringing your printer in for repair. We are not responsible for any data loss that may occur during the repair process.</p>

      <h3 className="main_heading_bar_terms_conditions">Cancellation and Refunds:</h3>
      <p>If you wish to cancel the repair service, please notify us as soon as possible. Refunds for cancelled services will be handled on a case-by-case basis.</p>

      <h3 className="main_heading_bar_terms_conditions">Governing Law:</h3>
      <p>These terms and conditions are governed by the laws of [your jurisdiction].</p>

      <h3 className="main_heading_bar_terms_conditions">Contact Information:</h3>
      <ul>
        <li>For any questions or concerns regarding our printer repair services, please contact our customer service at [customer service contact information].</li>
      </ul>

      <p>Please note that this is a general template, and you should customize it to meet the specific needs of your printer repair business. It's also important to have legal counsel review and help you finalize your terms and conditions to ensure they are legally sound and compliant with relevant regulations in your jurisdiction.</p>
    </div>
  </div>
</section>

    </>
  );
};

export default TermsConditions;
