import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apibaseURlweb, showSuccessToast, showErrorToast, fileupload } from "../../../Function";
import { RxCross2 } from "react-icons/rx";

const BookingInfoset = ({ isModel, islabel, HomePageCheck, datasetting }) => {
    const navigate = useNavigate();
    const [timeSlots, setTimeSlots] = useState([]);
    const interval = 15; // minutes
    const [isLoading, setIsLoading] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [scrollY, setScrollY] = useState(0);
    const [successms, setSuccessms] = useState("");
    const [storematatag, setStorematatag] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [inputs, setInputs] = useState({
        full_name: '',
        // email: '',
        phone: '',
        printer_brand_name: '',
        // printer_model_number: '',
        // under_warrenty: '',
        printer_connected_with: '',
        // city: '',
        // state: '',
        // zip: "",
        // home_address: '',
        // booking_date: '',
        // booking_time: '',
        // description: '',
    });

    const [errors, setErrors] = useState({
        full_name: '',
        // email: '',
        phone: '',
        printer_brand_name: '',
        // printer_model_number: '',
        // under_warrenty: '',
        printer_connected_with: '',
        // city: '',
        // state: '',
        // zip: "",
        // home_address: '',
        // booking_date: '',
        // booking_time: '',
        // description: '',
    });



    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        setScrollY(window.scrollY);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((values) => ({ ...values, [name]: value }));
    };


    const handleSubmit1 = async (e) => {
        e.preventDefault();
        setSuccessms("")
        const servicedata = {
            full_name: inputs.full_name,
            // email: inputs.email,
            phone: inputs.phone,
            printer_brand_name: inputs.printer_brand_name,
            // printer_model_number: inputs.printer_model_number,
            // under_warrenty: inputs.under_warrenty,
            printer_connected_with: inputs.printer_connected_with,
            // city: inputs.city,
            // state: inputs.state,
            // zip: inputs.zip,
            // home_address: inputs.home_address,
        };
        setErrors("")
        try {
            setIsLoading(true);
            const response = await axios.post(`${apibaseURlweb}book/now/form/validation`, servicedata);
            if (response.status === 200) {
                if (response?.data?.status === true) {
                    setSuccessms(response?.data?.msg)
                    handleNext();
                } else {
                    if (response?.data?.errors) {
                        const errorMessages = getValidationErrors(response?.data?.errors);
                        setErrors(errorMessages);
                    } else {
                        showErrorToast('General Error', response?.data?.msg);
                    }
                }
            }
        } catch (error) {

        } finally {
            setIsLoading(false); 
        }
    };

    const handleSubmit = async (e) => {
        handleNext();
        e.preventDefault();
        setSuccessms("Booking Slot Confirmed")

        const servicedata = {
            full_name: inputs.full_name,
            // email: inputs.email,
            phone: inputs.phone,
            printer_brand_name: inputs.printer_brand_name,
            // printer_model_number: inputs.printer_model_number,
            // under_warrenty: inputs.under_warrenty,
            printer_connected_with: inputs.printer_connected_with,
            // city: inputs.city,
            // state: inputs.state,
            // zip: inputs.zip,
            // home_address: inputs.home_address,
            // booking_date: inputs.booking_date,
            // booking_time: inputs.booking_time,
            // description: inputs.description,
        };

        setErrors("")
        try {
            setIsLoading(true);
            const response = await axios.post(`${apibaseURlweb}book/now/form`, servicedata);
            console.log(response);
            
            if (response.status === 200) {
                if (response?.data?.status === true) {
                    setSuccessms(response?.data?.msg)
                    console.log('success');
                    
                    setInputs({
                        full_name: '',
                        // email: '',
                        phone: '',
                        printer_brand_name: '',
                        // printer_model_number: '',
                        // under_warrenty: '',
                        printer_connected_with: '',
                        // city: '',
                        // state: '',
                        // zip: "",
                        // home_address: '',
                        // booking_date: '',
                        // booking_time: '',
                        // description: '',
                    });
                    setCurrentStep(1);
                    setTimeout(() => {
                        navigate('/thankyou'); 
                        setSuccessms('');
                      }, 1200);
                } else {
                    if (response?.data?.errors) {
                        const errorMessages = getValidationErrors(response?.data?.errors);
               
                        setErrors(errorMessages);
                    } else {
                        // showErrorToast('General Error', response?.data?.msg);
                    }
                }
            }
        } catch (error) {

        } finally {
            setIsLoading(false); // Stop loading, regardless of the response
        }
    };

    const getValidationErrors = (errors) => {

        let errorMessages = {
            full_name: '',
            // email: '',
            phone: '',
            printer_brand_name: '',
            // printer_model_number: '',
            // under_warrenty: '',
            printer_connected_with: '',
            // city: '',
            // state: '',
            // zip: "",
            // home_address: '',
        };

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                errorMessages[key] = errors[key].join(', ');
            }
        }
        return errorMessages;
    };



    const handleNext = () => {
        setCurrentStep(2);
    };
    const handlePrevious = () => {
        setCurrentStep(1);
    }

    // bookin time selecter ==========================================>
        const convertTo24HourFormat = (time12h) => {
            const [time, modifier] = time12h.split(' ');
            let [hours, minutes] = time.split(':').map(Number);
            if (modifier === 'PM' && hours !== 12) hours += 12;
            if (modifier === 'AM' && hours === 12) hours = 0;
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
        };
        
        // Utility function to convert 24-hour time to 12-hour time
        const convertTo12HourFormat = (time24h) => {
            let [hours, minutes, seconds] = time24h.split(':').map(Number);
            const modifier = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12; // convert 24-hour to 12-hour
            minutes = minutes.toString().padStart(2, '0');
            seconds = seconds.toString().padStart(2, '0');
            return `${hours}:${minutes}:${seconds} ${modifier}`;
        };
        
        // Utility function to format time to HH:MM:SS
        const formatTimeToHHMMSS = (date) => {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        };

    useEffect(() => {
        const generateTimeSlots = () => {
            if (!datasetting || !datasetting.office_open_time || !datasetting.office_close_time) {
                return [];
            }

            if (!inputs.booking_date) {
                return [];
            }

            let officeOpenTime = datasetting.office_open_time;
            let officeCloseTime = datasetting.office_close_time;

            // Convert to 24-hour format
            officeOpenTime = convertTo24HourFormat(officeOpenTime);
            officeCloseTime = convertTo24HourFormat(officeCloseTime);

            const slots = [];
            const start = new Date();
            const [openHour, openMinute] = officeOpenTime.split(':').map(Number);
            start.setHours(openHour, openMinute, 0, 0);

            const end = new Date();
            const [closeHour, closeMinute] = officeCloseTime.split(':').map(Number);
            end.setHours(closeHour, closeMinute, 0, 0);

            const now = new Date();

            if (inputs.booking_date === now.toISOString().split('T')[0]) {
                // If selected date is today, start from current time
                if (now > start) {
                    const roundedMinutes = Math.ceil(now.getMinutes() / interval) * interval;
                    start.setHours(now.getHours(), roundedMinutes, 0, 0);
                }
            }

            // Generate time slots
            while (start <= end) {
                const timeSlot24 = formatTimeToHHMMSS(start); // Get time in HH:MM:SS (24-hour)
                const timeSlot12 = convertTo12HourFormat(timeSlot24); // Convert to 12-hour format
                slots.push({ timeSlot24, timeSlot12 });
                start.setMinutes(start.getMinutes() + interval);
            }

            return slots;
        };

        setTimeSlots(generateTimeSlots());
    }, [datasetting, inputs.booking_date]);

    // bookin time selecter ==========================================>

    return (

        // <form className={`row form_book_now_set_for_fron_page ${scrollY >= 614 && scrollY <= 2667 ? "custom_css_app_for_set":""}`} onSubmit={handleSubmit}>
        <form className={`row ${isModel == true || islabel == true ? "" : "form_book_now_set_for_fron_page"} ${HomePageCheck == true && scrollY >= 614 && scrollY <= 2667 ? "custom_css_app_for_set" : ""} `} onSubmit={handleSubmit}>
            {successms ?
                <div className="col-12 col-md-12">
                    <div class="alert alert-success p-1" role="alert">
                        {successms}
                    </div>
                </div>
                : ""}


            {currentStep === 1 && (
                <>
                    <div className="col-12 col-md-6">
                        <div className="mb-1">
                            {islabel == true && (<label> Name</label>)}
                            <input
                                className="form-control form-quriar-control"
                                id="inputName"
                                type="text"
                                placeholder="Full name"
                                name="full_name"
                                value={inputs.full_name}
                                onChange={handleChange}
                            />
                            <p className="text-danger error_check_ms_now">{errors.full_name}</p>
                        </div>
                    </div>
                    {/* <div className="col-12 col-md-6">
                        <div className="mb-1">
                            {islabel == true && (<label> Email</label>)}
                            <input
                                className="form-control form-quriar-control"
                                id="email"
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={inputs.email}
                                onChange={handleChange}
                            />
                            <p className="text-danger error_check_ms_now">{errors.email}</p>
                        </div>
                    </div> */}

                    <div className="col-12 col-md-6">
                        <div className="mb-1">
                        {islabel && (
                            <div>
                                <label htmlFor="number">Phone</label>
                                <small>(format: 999-999-9999)</small>
                            </div>
                            )}

                            <InputMask
                            mask="999-999-9999"
                            className="form-control form-quriar-control"
                            id="number"
                            type="text"
                            placeholder="Phone (format: 999-999-9999)"
                            name="phone"
                            value={inputs.phone}
                            onChange={handleChange}
                            />
                            <p className="text-danger error_check_ms_now">{errors.phone}</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="mb-1">
                            {islabel == true && (<label> Printer Brand Name</label>)}
                            <input
                                className="form-control form-quriar-control"
                                id="inputName"
                                type="text"
                                placeholder="Printer Brand Name"
                                name="printer_brand_name"
                                value={inputs.printer_brand_name}
                                onChange={handleChange}
                            />
                            <p className="text-danger error_check_ms_now">{errors.printer_brand_name}</p>
                        </div>
                    </div>

                    {/* <div className="col-12 col-md-6">
                        <div className="mb-1">
                            {islabel == true && (<label> Printer Model Number</label>)}
                            <input
                                className="form-control form-quriar-control"
                                id="inputName"
                                type="text"
                                placeholder="Printer Model Number"
                                name="printer_model_number"
                                value={inputs.printer_model_number}
                                onChange={handleChange}
                            />
                            <p className="text-danger error_check_ms_now">{errors.printer_model_number}</p>
                        </div>
                    </div> */}

                    {/* <div className="col-12 col-md-6">
                        <div className="mb-1">
                            {islabel == true && (<label>Under Warrenty</label>)}
                            <select
                                className="form-control form-quriar-control"
                                id="exampleFormControlSelect1"
                                aria-label="Default select example"
                                name="under_warrenty"
                                value={inputs.under_warrenty}
                                onChange={handleChange}>
                                <option>Under Warrenty</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <p className="text-danger error_check_ms_now">{errors.under_warrenty}</p>
                        </div>
                    </div> */}

                    <div className="col-12 col-md-6">
                        <div className="mb-3">
                            {islabel == true && (<label>Printer connected with</label>)}
                            <select
                                className="form-control form-quriar-control"
                                id="exampleFormControlSelect1"
                                aria-label="Default select example"
                                name="printer_connected_with"
                                value={inputs.printer_connected_with}
                                onChange={handleChange}
                            >
                                <option>Printer connected with</option>
                                <option value="Windows">Windows</option>
                                <option value="Mac">Mac</option>

                            </select>
                            <p className="text-danger error_check_ms_now">{errors.printer_connected_with}</p>
                        </div>
                    </div>

                    {/* <div className="col-12 col-md-6">
                        <div className="mb-1">
                            {islabel == true && (<label>City</label>)}
                            <input
                                className="form-control form-quriar-control"
                                id="inputName"
                                type="text"
                                placeholder="City"
                                name="city"
                                value={inputs.city}
                                onChange={handleChange}
                            />
                            <p className="text-danger error_check_ms_now">{errors.city}</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="mb-1">
                            {islabel == true && (<label>State</label>)}
                            <input
                                className="form-control form-quriar-control"
                                id="inputName"
                                type="text"
                                placeholder="State"
                                name="state"
                                value={inputs.state}
                                onChange={handleChange}
                            />
                            <p className="text-danger error_check_ms_now">{errors.state}</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="mb-1">
                            {islabel == true && (<label>Zip Code</label>)}
                            <input
                                className="form-control form-quriar-control"
                                id="inputName"
                                type="text"
                                placeholder="Zip Code"
                                name="zip"
                                value={inputs.zip}
                                onChange={handleChange}
                            />
                            <p className="text-danger error_check_ms_now">{errors.zip}</p>
                        </div>
                    </div>

                    <div className="mb-1">

                        <div className="col-12 col-md-12">
                            <div className="mb-1">
                                {islabel == true && (<label>Home address</label>)}
                                <input
                                    className="form-control form-quriar-control"
                                    id="inputName"
                                    type="text"
                                    placeholder="Home address"
                                    value={inputs.home_address}
                                    name="home_address"
                                    onChange={handleChange}
                                />
                                <p className="text-danger error_check_ms_now">{errors.home_address}</p>
                            </div>
                        </div>
                    </div> */}


                    <div className="d-grid">
                        <button className="btn btn-primary booking_now_check booking_now_check_for_submit_result" type="submit" disabled={isLoading}>
                            {isLoading ? 'Sending...' : datasetting.booking_button_btn_2} Book For Free
                        </button>
                        {/* <button className="btn btn-primary booking_now_check booking_now_check_for_submit_result" onClick={handleSubmit1} type="button" disabled={isLoading}>
                            {isLoading ? 'Sending...' : datasetting.booking_button_btn_1}
                        </button> */}
                    </div>

                </>
            )}

            {/* {currentStep === 2 && (
                <>
                    <div className="col-12 col-md-12">
                        <div className="mb-1">
                            <label> Booking Date</label>
                            <input
                                className="form_quriar_control"
                                id="inputName"
                                type="date"
                                placeholder="Booking Date"
                                name="booking_date"
                                value={inputs.booking_date}
                                onChange={handleChange}
                            />
                            <p className="text-danger error_check_ms_now_check">{errors.booking_date}</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-12">
                        <div className="mb-1">
                            <label>Select Time</label>
                            <select className="form_quriar_control" id="exampleFormControlSelect1" aria-label="Default select example" name="booking_time" onChange={handleChange}>
                                <option value="">Select Time</option>
                                {timeSlots.length > 0 ? (
                                    timeSlots.map((slot, index) => (
                                        <option key={index} value={slot.timeSlot24}>{slot.timeSlot12}</option>
                                    ))
                                ) : (
                                    <option>No Time Slots Available</option>
                                )}
                            </select>
                            <p className="text-danger error_check_ms_now_check">{errors.booking_time}</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-12">
                        <div className="mb-1">
                            <label>Description</label>
                            <textarea
                            className="form-control form-quriar-control"
                            id="inputDescription"
                            rows="6"
                            placeholder="Enter description here"
                            name="description"
                            value={inputs.description}
                            onChange={handleChange}
                            style={{ height: '100px' }} 
                            />
                            <p className="text-danger error_check_ms_now_check">{errors.description}</p>
                        </div>
                        </div>


                    <div className="d-grid">
                        <button className="btn btn-primary booking_now_check booking_now_check_for_submit_result" type="submit" disabled={isLoading}>
                            {isLoading ? 'Sending...' : datasetting.booking_button_btn_2}
                        </button>

                        <button className="btn btn-primary booking_now_check booking_now_check_for_submit_result" type="button" onClick={handlePrevious}>
                            Previous
                        </button>
                    </div>
                </>
            )} */}

        </form>
    )
}

export default BookingInfoset;