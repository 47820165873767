import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
const Privacypolicy = ({metadescription}) => {
  const[storematatag,setStorematatag] = useState("");


  useEffect(()=>{
    const targetSlug = 'privacy-and-policy';
    const targetObject = metadescription.find(item => item.slug === targetSlug);
    setStorematatag(targetObject)
   },[metadescription])
  return (
    <>

    {storematatag  &&
      <Helmet>
        <meta name="description" content={storematatag.meta_key_discription} />
        <meta name="keywords" content={storematatag.meta_keyword} />
      </Helmet>}
      <section className="privecy_policy mt-5">
  <div className="container">
    <h1 className="heading_sub_page_for_custome">Printer Repair Services</h1>
    <div className="printer_repair_div">
      <h3 className="mt-1 mb-3 main_heading_bar_printer_repair">Introduction:</h3>
      <p>
        Welcome to our Printer Repair Services. We specialize in providing top-notch repair and maintenance services for all types of printers, ensuring that your equipment is up and running smoothly in no time.
      </p>

      <h3 className="main_heading_bar_printer_repair">Services We Offer:</h3>
      <p>We offer a wide range of printer repair services, including:</p>

      <ul>
        <li>
          Diagnostic Services: Comprehensive diagnostics to identify and troubleshoot printer issues.
        </li>
        <li>
          Repair Services: Professional repair services for all major printer brands and models.
        </li>
        <li>
          Maintenance Services: Regular maintenance to prevent future issues and prolong the lifespan of your printer.
        </li>
        <li>
          Replacement Parts: High-quality replacement parts for faulty components.
        </li>
      </ul>

      <h3 className="main_heading_bar_printer_repair">Why Choose Us:</h3>
      <p>Here are a few reasons to choose our services:</p>

      <ul>
        <li>
          Experienced Technicians: Our team consists of skilled technicians with years of experience in printer repair.
        </li>
        <li>
          Quick Turnaround: We understand the importance of a functional printer, and we strive to provide quick and efficient services.
        </li>
        <li>
          Affordable Rates: We offer competitive pricing without compromising on the quality of our services.
        </li>
        <li>
          Customer Satisfaction: Our top priority is customer satisfaction. We ensure that our services meet and exceed your expectations.
        </li>
      </ul>

      <h3 className="main_heading_bar_printer_repair">Contact Us:</h3>
      <p>
        If you have any questions, need a quote, or want to schedule a repair service, please contact us:
      </p>
      <ul>
        <li>Phone: [Your Phone Number]</li>
        <li>Email: [Your Email Address]</li>
        <li>Address: [Your Address]</li>
      </ul>

      <h3 className="main_heading_bar_printer_repair">Customer Reviews:</h3>
      <p>
        Don’t just take our word for it. Here’s what our customers have to say about our services:
      </p>
      <ul>
        <li>"Excellent service! My printer is working like new." - John D.</li>
        <li>"Quick and reliable repair. Highly recommend!" - Sarah K.</li>
        <li>"Great value for money. Professional and friendly staff." - Mark T.</li>
      </ul>
    </div>
  </div>
      </section>
    </>
  );
};

export default Privacypolicy;
