import React,{useState,useEffect} from 'react'
import serviceimg1 from '../../../front/assest/img/icons/services-1.svg'
import { apibaseURlweb,filebasepath} from "../../../Function";
import axios from 'axios';
import { Link } from 'react-router-dom';

const HomeService = ({isFeatured,servicedata,setFeaturedata ,service_top_padding , service_top_rep , isCol}) => {

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const Shortdesc = ({desc}) => {
    // const tempElement = document.createElement('div');
    // tempElement.innerHTML = desc;
    // const plainText = tempElement.textContent;
    // const shortDESC = plainText.substring(0, 150);
   
    return (
        <span dangerouslySetInnerHTML={{ __html: desc }}></span>
      );
    
  };
  useEffect(()=>{
    setFeaturedata(isFeatured)
  },[])

  return (
    <>

<section className={`${service_top_padding } ${service_top_rep}`} id="services" container-xl="container-xl">
  <div className={`${isCol == true ? "container":""}`}>
    <div className={`row justify-content-center ${isCol == true ? "mt-5":""}`}>
      <div className="col-md-12 col-lg-12 text-center mb-3 mb-lg-4">
        <h5 className="text-danger">SERVICES</h5>
        <h2 className='sub_heding'>Our services for you</h2>
      </div>
    </div>
    <div className="row h-100 justify-content-center">
      {servicedata.map((item,index)=>{
        const truncatedText = item.descrip;
        return(
        <div key={item.id} className={`${isCol == true ? "col-12 col-md-4" : "col-12 col-md-6"} pt-4 px-md-2 px-lg-3`}>
        <div className="card h-100 card-span">
          <div className="card-body d-flex flex-column justify-content-around">
            <div className="text-center">
            { item.image ? 
              <img
                className="img-fluid"
                src={filebasepath + item.image} 
                alt="..."
                width="100%"
              />
              :""
            }
          
              <h5 className="my-4 my_decode_service">{item.title}</h5>
            </div>
            <p>
            
              <Shortdesc desc={item.descrip}  />
            </p>

            <div className="text-center">
              {/* <div className="d-grid">
                <Link className="btn btn-outline-danger booking_now_check" type="submit" to={`/services/${item.slug}`} onClick={scrollToTop}>
                  View more{" "}
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
        )
      })}

    </div>
  </div>

</section>

    </>
  )
}

export default HomeService
