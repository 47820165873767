import React, { useEffect, useState } from 'react'
import HomeService from './home/HomeService';
import { Helmet } from 'react-helmet';



const Service = ({servicedata,setFeaturedata,metadescription}) => {
  const[storematatag,setStorematatag] = useState("");

  useEffect(()=>{
    const targetSlug = 'service';
    const targetObject = metadescription.find(item => item.slug === targetSlug);
    setStorematatag(targetObject)
   },[metadescription])
  return (
    <>    

    {storematatag  &&
      <Helmet>
        <meta name="description" content={storematatag.meta_key_discription} />
        <meta name="keywords" content={storematatag.meta_keyword} />
      </Helmet>}
      <HomeService 
        isFeatured={false} 
        isCol={true}
        servicedata={servicedata}
        setFeaturedata={setFeaturedata }
        service_top_padding={"section_bar_for_service"}
         />
    </>
  )
}

export default Service;
