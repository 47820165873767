import React, { Suspense, lazy } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router";
import { ThreeCircles } from "react-loader-spinner";

const loaderStyles = {
   display: "flex",
   alignItems:"center",
   justifyContent:"center",
   height:"100vh"
};

function delayImport(delay) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(import('./FrontCss'));
    }, delay);
  });
}

const LazyComponent = lazy(() => delayImport(3000)); 

const FrontLayoute = ({datasetting}) => {
const Loader=()=>{
    return(
      <div className="loader loader_center" style={loaderStyles}>
          <ThreeCircles
            height={100}
            width={100}
            radius={5}
            color="#e566ad"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          
          />
    </div>
    )
  }
return (
    <>
      <Suspense  fallback={<Loader />}>
            <LazyComponent />
            <main className="main" id="top">
              <Header datasetting={datasetting} />
              <Outlet />
              <Footer datasetting={datasetting} />
            </main>
      </Suspense>
    </>
  );
};

export default FrontLayoute;
