import React, { useState, useEffect } from "react";
import { apibaseURl, showSuccessToast, fileupload, filebasepath } from "../../Function";
import axios from "axios";
import Swal from 'sweetalert2'

const Setting = () => {
  const [data, setdata] = useState({});
  const [imagelogo, setImagelogo] = useState("");
  const [imagefavicon, setImagefavicon] = useState("");
  const [bannerImg, setbannerImg] = useState("");
  // image upload api in ===============>
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${fileupload}addimage`, formData);
      if (response.status == 200) {
        if (response?.data.status == true) {
          if (e.target.name === "site_logo") {
            setImagelogo(response.data.data.image);
            setdata((prevData) => ({
              ...prevData,
              'site_logo': response.data.data.image,
            }));
          } else if (e.target.name === "site_icon") {
            setImagefavicon(response.data.data.image);
            setdata((prevData) => ({
              ...prevData,
              'site_icon': response.data.data.image,
            }));
          }else if(e.target.name === "Banner_image"){
            setbannerImg(response.data.data.image);
            setdata((prevData) => ({
              ...prevData,
              'Banner_image': response.data.data.image,
            }));
          }

          
        }
      } else {
      }
    } catch (error) {
    }
  };

  // deata show api =======================>
  const fetchsettingdata = async () => {
    try {
      const response = await axios.get(`${apibaseURl}settings`)
      if (response.status == 200) {
        if (response?.data.status == true) {
          console.log('Fetched data:', response.data.data);
          setdata(response.data.data);
        }
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchsettingdata();
  }, []);


  // data update api ============================>

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setdata((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  

    // Submit form data
    const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apibaseURl}editsetting`, data)
      if (response.status === 200) {
        if (response?.data.status == true) {
          showSuccessToast(response?.data.msg)
          fetchsettingdata();
        }
      } else {
      }
    } catch (error) {
    }
  };
  // delete image api call for setting ==========================>
  const handledeleteimageforlogo =()=>{
    setdata((prevData) => ({
      ...prevData,
      'site_logo': "",
    }));
  }

  const handledeleteimageforfavicon = () =>{
    setdata((prevData) => ({
      ...prevData,
      'site_icon':"",
    }));
  }

  const handledeleteimageforBannerImage = () =>{
    setdata((prevData) => ({
      ...prevData,
      'Banner_image':"",
    }));
  }

  return (
    <>
      <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
          <div class="col-md-12">
            <div class="card mb-4">
              <h5 className="card-header">Settings</h5>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-12 mb-3">
                      <label>Site Titile</label>
                      <input
                        type="text"
                        className="form-control"
                        name="site_title"
                        placeholder="Site Titile"
                        value={data.site_title}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Site Logo</label><br></br>
                      {data.site_logo ?
                        <div className='image_wapre_div_for_data_image'>
                          <img src={`${filebasepath}${data.site_logo}`} alt="img" width="100px" className="mb-2" />
                          <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={handledeleteimageforlogo}></i>
                        </div>
                        : ""
                      }

                      <input
                        type="file"
                        className="form-control"
                        name="site_logo"
                        onChange={handleFileUpload}
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label>Site Favicon</label><br></br>
                      {data.site_icon ?
                        <div className='image_wapre_div_for_data_image'>
                          <img src={`${filebasepath}${data.site_icon}`} alt="img" width="50px" className="mb-2" />
                          <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={handledeleteimageforfavicon}></i>
                        </div>
                        : ""
                      }

                      <input
                        type="file"
                        className="form-control"
                        name="site_icon"
                        onChange={handleFileUpload}
                      />
                    </div>


                    <div className="form-group col-md-12 mb-3">
                      <label>Home Page Banner Image</label><br></br>
                      {data.Banner_image ?
                        <div className='image_wapre_div_for_data_image'>
                          <img src={`${filebasepath}${data.Banner_image}`} alt="img" width="50px" className="mb-2" />
                          <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={handledeleteimageforBannerImage}></i>
                        </div>
                        : ""
                      }

                      <input
                        type="file"
                        className="form-control"
                        name="Banner_image"
                        onChange={handleFileUpload}
                      />
                    </div>


                    
                    <div className="form-group col-md-12 mb-3">
                      <label>Contact Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="admin_email"
                        value={data.admin_email}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* <div className="form-group col-md-6 mb-3">
                      <label>Contact Email Second</label>
                      <input
                        type="text"
                        className="form-control"
                        name="admin_email_two"
                        value={data.admin_email_two}
                        onChange={handleInputChange}
                      />
                    </div> */}
                    <div className="form-group col-md-6 mb-3">
                      <label>Contact Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="admin_phone"
                        value={data.admin_phone}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* <div className="form-group col-md-6 mb-3">
                      <label>Admin Phone Second</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="admin_phone_two"
                        value={data.admin_phone_two}
                        onChange={handleInputChange}
                      />
                    </div> */}

                    <div className="form-group col-md-6 mb-3">
                      <label>Contact Fax</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="fax_number_one"
                        value={data.fax_number_one}
                        onChange={handleInputChange}
                      />
                    </div>


                    <div className="form-group col-md-12 mb-3">
                      <label>Admin Address</label>
                      <textarea
                        className="form-control"
                        rows={5}
                        cols={12}
                        id="comment"
                        name="address"
                        value={data.address}
                        onChange={handleInputChange}
                      >
                        Address
                      </textarea>
                    </div>


{/* 
                    <div className="form-group col-md-6 mb-3">
                      <label>Admin Fax Second</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="fax_number_two"
                        value={data.fax_number_two}
                        onChange={handleInputChange}
                      />
                    </div> */}


                    {/* <div className="form-group col-md-6 mb-3">
                      <label>Admin Web</label>
                      <input
                        type="text"
                        className="form-control"
                        name="web_link_one"
                        value={data.web_link_one}
                        onChange={handleInputChange}
                      />
                    </div> */}


                    {/* <div className="form-group col-md-6 mb-3">
                      <label>Admin Web Second</label>
                      <input
                        type="text"
                        className="form-control"
                        name="web_link_two"
                        value={data.web_link_two}
                        onChange={handleInputChange}
                      />
                    </div> */}


                  <div className="form-group col-md-6 mb-3">
                      <label>Home Page Button name</label>
                      <input
                         type="text"
                         className="form-control"
                         name="Banner_button_name"
                         placeholder="Home Page Button name"
                         value={data.Banner_button_name}
                         onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Home Page Button Link</label>
                      <input
                         type="text"
                         className="form-control"
                         name="banner_link"
                         placeholder="Home Page Button Link"
                         value={data.banner_link}
                         onChange={handleInputChange}
                      />
                    </div>

                    
                    <div className="form-group col-md-12 mb-3">
                      <label>Popup Show duration (Seconds)</label>
                      <input
                         type="text"
                         className="form-control"
                         name="popup_timer"
                         placeholder="Popup Show duration"
                         value={data.popup_timer}
                         onChange={handleInputChange}
                      />
                    </div>

                    

                    <div className="form-group col-md-6 mb-3">
                      <label>office open time</label>
                      <input
                         type="text"
                         className="form-control"
                         name="office_open_time"
                         placeholder="office_open_time"
                         value={data.office_open_time}
                         onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label>office Close time</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="office Close time"
                        name="office_close_time"
                        value={data.office_close_time}
                        onChange={handleInputChange}
                      />
                    </div>

                    
                    <div className="form-group col-md-12 mb-3">
                      <label>Live Chat Key </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Live_chat"
                        value={data.Live_chat}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <label>Google map</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Map"
                        value={data.Map}
                        onChange={handleInputChange}
                      />
                    </div>
                      
                    <div className="form-group col-md-6 mb-3">
                      <label>Booking Form Button 1</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Next"
                        name="booking_button_btn_1"
                        value={data.booking_button_btn_1}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Booking Form Button 2</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Next"
                        name="booking_button_btn_2"
                        value={data.booking_button_btn_2}
                        onChange={handleInputChange}
                      />
                    </div>


                    <div className="form-group col-md-6 mb-3">
                      <label>Facebook Icon</label>
                      <input
                        type="text"
                        className="form-control"
                        name="fb_icon"
                        value={data.fb_icon}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Youtub Icon</label>
                      <input
                        type="text"
                        className="form-control"
                        name="yt_icon"
                        value={data.yt_icon}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Instagram Icon</label>
                      <input
                        type="text"
                        className="form-control"
                        name="ig_icon"
                        value={data.ig_icon}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>linkedin Icon</label>
                      <input
                        type="text"
                        className="form-control"
                        name="in_icon"
                        value={data.in_icon}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;


