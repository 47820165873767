import React, { useEffect }  from 'react';
import { Helmet } from 'react-helmet';

const ThankYouPage = () => {
    return (
        <>      
        <Helmet>
        <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-16594954469/_WfhCJqbhNUZEOXRi-k9'});
          `}
        </script>
      </Helmet>
            <section className='pb-0'>
                <div className="container mt-5 text-center thankucontainer">
                    <h2 className='thankutitle'>Thankyou!!</h2>
                    <br/>
                    <h4 className='thankucontent'>Your complaint has been registered with us. We will shortly contact you.</h4>
                    <span>or</span>
                    <h4 className='thankucontent'>Call us directly at: <a href='tel:+1-518-512-9203'>+1-518-512-9203</a></h4>
                </div>
            </section>
        </>
    )
}

export default ThankYouPage;
