import React, { useState } from 'react';
import { apibaseURl, showSuccessToast, showErrorToast, fileupload ,filebasepath} from "../../../Function";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';


const Addslider = () => {
  const [inputs, setInputs] = useState({
    title: '',
    discription: '',
    button: '',
    link: '',
    sort: '0',
    status: '',
  });

  const [image, setImage] = useState('');
  const [progressCount, setprogressCount] = useState(0);
  const navigate = useNavigate();

  // image upload api in ==================================> 
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axios.post(`${fileupload}addimage`, formData,{
        onUploadProgress: (data) => {
          const percentCompletedto = Math.round((data.loaded/data.total)*100);
          setprogressCount(percentCompletedto);
          if (percentCompletedto == 100) {
            setTimeout(()=>{
            setprogressCount(0);
          },4000)
          }
        },
      });
      if (response.status === 200 && response.data.status === true) {
        setImage(response.data.data.image);
        showSuccessToast(response?.data?.msg);
      }
    } catch (error) {
 
    }
  };

  // data insert in  api ==============>
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const servicedata = {
      title: inputs.title,
      image: image,
      discription: inputs.discription,
      button: inputs.button,
      link: inputs.link,
      sort: inputs.sort,
      status: inputs.status,
    };
    try {
      const response = await axios.post(`${apibaseURl}addslider`, servicedata);
      if (response.status === 200) {
        if (response?.data?.status === true) {
          setInputs(response.data.data);
          navigate('/admin/sliderfor');
          showSuccessToast(response?.data?.msg);
        }
        else {
          if (response?.data?.error) {
             const errorMessages = getValidationErrors(response?.data?.error);
            showErrorToast(errorMessages);
          } else {
            showErrorToast('General Error', response?.data?.msg);
          }
        }
      }

    } catch (error) {
 
    }
  };

  const getValidationErrors = (errors) => {
    let errorMessages =[];
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorMessages.push(<div key={key}>{errors[key][0]}</div>);
      }else{
      }
    }
    
    const storeAllValidtionErr = (
          <>
            {errorMessages}
          </>
        );
        return storeAllValidtionErr;
   
  };

  const handledeleteimage = () => {
    setImage("");
  };
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="data_acrd_wrapper">
                <h5 className="card-header">Add Slider</h5>
                <Link to="/admin/sliderfor" className="btn btn-primary primary_ya">
                  <span className="class_plus">
                    <IoMdArrowRoundBack />
                  </span>
                  Back
                </Link>
              </div>
              <div className="add_divider">
                {' '}
                <hr />
              </div>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Slider Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider Title"
                      name="title"
                      value={inputs.title}
                      onChange={handleChange}
                    />
                  </div>

                  {/* <div className="mb-3">
                    <label htmlFor="formFileMultiple" className="form-label">
                    Slider Image
                    </label>
                    <br/>
                      {image ? 
                        <div className="image_wapre_div_for_data_image">
                      <img src={filebasepath + image} alt="Avatar" className=""  width="50px" /> 
                      <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={handledeleteimage}></i>
                      </div>
                      : ""}
                    <input className="form-control mt-1"
                     type="file" 
                     id="formFileMultiple1" 
                     name="serviceicon"
                     onChange={handleFileUpload} />
                    {progressCount > 0 && (
                      <div class="progress mt-2">
                        <div
                          class="progress-bar progress-bar-striped bg-danger progress-bar-animated progress_bar_height_decode"
                          role="progressbar"
                          aria-valuenow={progressCount}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${progressCount}%` }}
                        >{`${progressCount}%`}</div>
                      </div>
                    )}
                  </div> */}

                  <div className="mb-3">
                    <label className="form-label">Slider Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider Description"
                      name="discription"
                      value={inputs.discription}
                      onChange={handleChange}
                    />
                  </div>



                  <div className="mb-3">
                    <label className="form-label">Slider button</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider button"
                      name="button"
                      value={inputs.button}
                      onChange={handleChange}
                    />
                  </div>



                  <div className="mb-3">
                    <label className="form-label">Slider Button Link</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider Button Link"
                      name="link"
                      value={inputs.link}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Slider sort</label>
                    <input
                      type="number"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider sort"
                      name="sort"
                      value={inputs.sort}
                      onChange={handleChange}
                    />
                  </div>
      

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlSelect1" className="form-label">
                      Service Status
                    </label>
                    <select
                      className="form-select"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      name="status"
                      value={inputs.status}
                      onChange={handleChange}
                    >
                      <option>select Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>

                  <div className="mb-3 text-center">
                    <button type="submit" className="btn btn-primary">
                      submit
                    </button>
                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addslider;

