import {  toast , Zoom } from 'react-toastify';

export const filebasepath = 'https://bookmytechnician.com/server/uploads/';
export const apibaseURl = 'https://bookmytechnician.com/server/api/admin/';
export const apibaseURlweb = 'https://bookmytechnician.com/server/api/web/';
export const fileupload = 'https://bookmytechnician.com/server/api/';

export const tokengenerate = () => 
{
    let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
    if(getuserloggedIn)
    {
     
      if(getuserloggedIn.token !== undefined && getuserloggedIn.token !== '')
      {
        return getuserloggedIn.token;
      }
    }
    return false;
};


export const getLoggedInUserDetails = (key='') => 
{
    let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
    if(getuserloggedIn)
    {
     
      return getuserloggedIn?.user[key];
    }
    return false;
};


export const getLoggedInuserRole = () => 
{
    let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
    //alert(getuserloggedIn)
    if(getuserloggedIn != null && getuserloggedIn.user['role'] !== undefined && getuserloggedIn.user['role'] !== '')
    {
      return getuserloggedIn.user['role'];
    }
    return false;
};

export const showErrorToast = (text, autoClose = '5000', positions='top-right') => {
    toast.error(text,
        {
          autoClose: autoClose,
          position: positions,
          transition: Zoom,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: true,
        });
};

export const showSuccessToast = (text, autoClose = '3000', positions='top-right') => {
    toast.success(text,
        {
          autoClose: autoClose,
          position: positions,
          transition: Zoom,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: true,
        })
};

// export const getvalidationErr = (errors) => {
//   const errorsConvertInArry = Object.values(errors);
//   let collectAllValidationErr = [];
//   errorsConvertInArry.forEach((error, index) => {
//     collectAllValidationErr.push(<div key={index}>{error.msg}</div>);
//   });
//   const storeAllValidtionErr = (
//     <>
//       {collectAllValidationErr}
//     </>
//   );
//   return storeAllValidtionErr;
// }


 
export const getValidationErrors = (errors) => {
    let errorMessages = '';
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorMessages += `${errors[key].join(', ')}\n`;
      }else{
        errorMessages += `${errors[key](', ')}\n`;
      }
    }
    return errorMessages;
  };
