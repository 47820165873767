import React, { useEffect } from 'react';
const TawkToChat = ({ datasetting }) => {
  useEffect(() => {
    const loadTawkToScript = () => {
      const script = document.createElement('script');
      script.src = `https://embed.tawk.to/${datasetting.Live_chat}`; 
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');
      script.async = true;
      document.body.appendChild(script);
    };
    loadTawkToScript();
    return () => {
      const script = document.querySelector(`script[src="https://embed.tawk.to/${datasetting.Live_chat}"]`); // Correct URL format
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [datasetting.Live_chat]); 

  return null; 
};

export default TawkToChat;