import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { BiPlusMedical } from "react-icons/bi";
import { apibaseURl,filebasepath } from '../../../Function';
import axios from 'axios';
import Swal from 'sweetalert2'
import { ColorRing } from 'react-loader-spinner'
import { BsImage } from "react-icons/bs";

const AdminService = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // deta fech show ==============================>
  const showservicedata = async () => {
    try {
      const responce = await axios.get(`${apibaseURl}services`);
      if (responce.status === 200) {
        if (responce?.data?.status === true) {
          setData(responce.data.data);
          setLoading(false)
    
        }
      }
    } catch (error) {
    
    }
  };

  useEffect(() => {
    showservicedata();
  }, []);

  // deta update status ==============================>
  const statusupadte = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to update this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const connectapi = handleUpdatestaus(id)
        connectapi.then((responses)=>{
         if(responses.status==200){
          if(responses?.data?.status == true){
            Swal.fire(
              'Updated!',
              'Your status has been updated.',
              'success'
            )
            showservicedata();
          }else{
            Swal.fire(
              'Oops, something went wrong!',
              'Please try again.',
              'error'
            )
          }
         
         }
        })
      }
    })
   }

   const handleUpdatestaus = async (id) => {
    const responce = await axios.get(`${apibaseURl}service/status-update/${id}`);
    return responce;
    }

    // deta update status ==============================>
    const servicefeaturedupadte = async (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to update this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it!'
      }).then((result) => {
        if (result.isConfirmed) {
          const connectapi = handleUpdatestausFeatured(id)
          connectapi.then((responses)=>{
           if(responses.status==200){
            if(responses?.data?.status == true){
              Swal.fire(
                'Updated!',
                'Your status has been updated.',
                'success'
              )
              showservicedata();
            }else{
              Swal.fire(
                'Oops, something went wrong!',
                'Please try again.',
                'error'
              )
            }
           
           }
          })
        }
      })
     }
  
     const handleUpdatestausFeatured = async (id) => {
      const responce = await axios.get(`${apibaseURl}service/featured/status-update/${id}`);
      return responce;
      }
    // deta fech show ==============================>
  
  const handledelete =(id)=>{
 
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const connectapi = deleteApiCall(id)
        connectapi.then((responses)=>{
         if(responses.status==200){
          if(responses?.data?.status == true){
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            showservicedata();
          }else{
            Swal.fire(
              'Opps Something Wrong?',
              'That thing is still around?',
              'question'
            )
          }
         
         }
        })
      }
    })
   }



     
   const deleteApiCall = async (id) => {
    const responses = await axios.delete(`${apibaseURl}deleteservice/${id}`);
    return responses;
  
  }
  return (

    <>
    
       <div class="container-xxl flex-grow-1 container-p-y">
         <div className="card">
         <>
         <div className="data_acrd_wrapper">
            <h5 className="card-header">Services</h5>
            <Link class="btn btn-primary primary_ya" to="addservice"><span className='class_plus'><BiPlusMedical/></span>Add Service</Link>
          </div>
            {loading ?
             <div className='loader_spinner_css_apply'>
                          <ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#313391', '#313391', '#313391', '#313391', '#313391']}
                        />
            </div>
             :
          <div className="table-responsive text-nowrap">
          
            <table className="table">
            <thead className="table-light">
                <tr>
                 <th>S.No.</th>
                 <th><BsImage className='custom_img_add_image' style={{fontSize:"20px"}}/></th> 
                 {/* <th>Service Header Image</th> */}
                 <th>Service Title</th>
                 <th>Service Featured</th>
                 <th>Status</th>
                 <th>Actions</th>
                </tr>
            </thead>
            <tbody className="table-border-bottom-0">
            {data.length >0 ? data.map((item,index)=>{
              return(
                <tr>
                <td>{index+1}</td>
                <td>
                
                {item.image ? 
                  <img src={filebasepath + item.image} alt="Avatar" className="" width="50px" />
                :
                  ""
                }
                </td>
                {/* <td>
                {item.header_image ? 
                  <img src={filebasepath + item.header_image} alt="Avatar" className="" width="150px" />
                :
                  <img src={serviceimg1} alt="Avatar" className="" width="50px" />
                }
                </td> */}
                <td>{item.title}</td>
                <td><button type="button" class={`btn btn-sm rounded-pill  ${item.service_featured == 'Yes' ? "btn-primary" : "btn-danger"}`} onClick={()=>servicefeaturedupadte(item.id)}>{item.service_featured}</button></td>
                <td><button type="button" class={`btn btn-sm rounded-pill  ${item.status == 'active' ? "btn-primary" : "btn-danger"}`} onClick={()=>statusupadte(item.id)}>{item.status == 'active' ? "Active" : "Inactive"}</button></td>

                <td>
                    <div className='action_button_preform'>
                     <Link class="btn btn-icon btn-primary link_decode" to={`editservice/${item.id}`}>
                        <span class="tf-icons"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                     </Link>

                     <button type="button" class="btn btn-icon btn-danger link_decode" onClick={()=>handledelete(item.id)}>
                        <span class="tf-icons"><i class="fa fa-trash" aria-hidden="true"></i></span>
                     </button>
                    </div>
                </td>
                </tr>
              )
            }):
            <tr><td className='md_center' colSpan={9}>No data Found</td></tr>
            } </tbody>
            </table>
        </div>

      }
    
        </>
         </div>
       </div>
    </>
  )
}

export default AdminService;
