import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { filebasepath } from "../../../Function";
import BookingNowModel from "../../pages/BookingNowModel";


const Header = ({ datasetting }) => {

  const [scroll, setScroll] = useState(false);
  const [click, setClick] = useState(false);
  const [data, setData] = useState(true);
  const handleNavLinkClick = () => {
    handleClick2();
  };
  const handleClick = () => setClick(!click)
  const handleClick2 = () => setData(!data)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);



  //  setting api call   ===========================>



  return (
    <>
    {/* <BookingNowModel datasetting={datasetting}/> */}
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top py-3 d-block back_bar ${scroll === true && "backdrop shadow-transition"} ${click === true && "shadow-transition"}`}>
        <div className="container">
          {datasetting.site_logo ?
            <Link className="navbar-brand m-0" to="/">
              <img src={filebasepath + datasetting.site_logo} alt="logo" height={45} />
            </Link>
            : ""
          }

          <button
            className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            onClick={handleClick} >
            <span className="navbar-toggler-icon"> </span>
          </button>
          <div className={`collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-0 justify-content-end  ${data ? "hide" : ""}`} id="navbarSupportedContent">
            <ul className="navbar-nav m-auto  pt-2 pt-lg-0 font-base">
              <li className="nav-item px-2">
                <NavLink className="nav-link" aria-current="page" to="/" onClick={handleNavLinkClick}  duration={500}>Home</NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink className="nav-link" to="/services" onClick={handleNavLinkClick}>Our Services</NavLink>
              </li>

              <li className="nav-item px-2">
                <NavLink className="nav-link" to="/about-us" onClick={handleNavLinkClick}>About Us</NavLink>
              </li>

              <li className="nav-item px-2">
                <NavLink className="nav-link" to="/contact-us" onClick={handleNavLinkClick}>Contact Us</NavLink>
              </li>

            </ul>

            <NavLink className="btn btn-primary order-1 order-lg-0 ms-lg-3 booking_now_check" to="/booking-now" onClick={handleNavLinkClick}>Book Your Technician</NavLink>
          </div>
        </div>
      </nav>
      {/* scroll to top =======================================> */}
    </>
  );
};

export default Header;
