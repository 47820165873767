import React, { useEffect, useState } from 'react'
import dashboard1 from '../../../admin/assest/img/illustrations/man-with-laptop-light.png'
import { MdWorkspacesOutline } from "react-icons/md";
import { apibaseURl } from '../../../Function';
import { FiRadio } from "react-icons/fi";
import { IoBookmarksOutline } from "react-icons/io5";
import axios from 'axios';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [data, setData] = useState({});

  const dashboarddatashow = async () => {
    try {
      const responce = await axios.get(`${apibaseURl}dashboardinfo`);
      console.log("responce view for dashbaord", responce)
      if (responce.status === 200) {
        setData(responce.data);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    dashboarddatashow()
  }, [])


  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-8 mb-4 order-0">
            <div className="card">
              <div className="row">
                <div className="col-sm-7">
                  <div className="card_body">
                    <h5 className="card_title text-primary top_bar_sect">
                      Congratulations Administrator !
                    </h5>
                  </div>
                </div>
                <div className="col-sm-5 text-center text-sm-left">
                  <div className="card-body pb-0 px-0 px-md-4">
                    <img
                      src={dashboard1}
                      height={140}
                      alt="View Badge User"
                      data-app-dark-img="illustrations/man-with-laptop-dark.png"
                      data-app-light-img="illustrations/man-with-laptop-light.png"
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6 mb-4">
            <Link to="/admin/adminservice">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <MdWorkspacesOutline className='file_text_icon' />
                    </div>

                  </div>
                  <span className="fw-semibold d-block mb-1 text_blue_set">Total Services</span>
                  <h3 className="card-title mb-2">{data.total_services}</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 col-md-12 col-6 mb-4">
            <Link to="/admin/adminservice">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <MdWorkspacesOutline className='file_text_icon' />
                    </div>
                  </div>
                  <span className='fw-semibold d-block mb-1 text_blue_set'>Total Active Services</span>
                  <h3 className="card-title text-nowrap mb-1">{data.total_active_services}</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 col-md-12 col-6 mb-4">
            <Link to="/admin/adminservice">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <MdWorkspacesOutline className='file_text_icon' />
                    </div>
                  </div>
                  <span className='fw-semibold d-block mb-1 text_blue_set'>Total Inactive Services</span>
                  <h3 className="card-title text-nowrap mb-1">{data.total_inactive_services}</h3>
                </div>
              </div>
            </Link>
          </div>



          <div className="col-lg-4 col-md-12 col-6 mb-4">
            <Link to="/admin/adminenquery">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <FiRadio className='file_text_icon' />
                    </div>
                  </div>
                  <span className='fw-semibold d-block mb-1 text_blue_set'>Total Enquirys </span>
                  <h3 className="card-title text-nowrap mb-1">{data.total_enquiry}</h3>
                </div>
              </div>
            </Link>
          </div>




          <div className="col-lg-4 col-md-12 col-6 mb-4">
            <Link to="/admin/bookings">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <IoBookmarksOutline className='file_text_icon' />
                    </div>
                  </div>
                  <span className='fw-semibold d-block mb-1 text_blue_set'>Total Bookings </span>
                  <h3 className="card-title text-nowrap mb-1">{data.total_booking}</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 col-md-12 col-6 mb-4">
            <Link to="/admin/bookings">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <IoBookmarksOutline className='file_text_icon' />
                    </div>
                  </div>
                  <span className='fw-semibold d-block mb-1 text_blue_set'>Total Approved Bookings </span>
                  <h3 className="card-title text-nowrap mb-1">{data.total_approved_booking}</h3>
                </div>
              </div>
            </Link>
          </div>


          <div className="col-lg-4 col-md-12 col-6 mb-4">
            <Link to="/admin/bookings">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <IoBookmarksOutline className='file_text_icon' />
                    </div>
                  </div>
                  <span className='fw-semibold d-block mb-1 text_blue_set'>Total Pendings Bookings </span>
                  <h3 className="card-title text-nowrap mb-1">{data.total_pending_booking}</h3>
                </div>
              </div>
            </Link>
          </div>



          <div className="col-lg-4 col-md-12 col-6 mb-4">
            <Link to="/admin/bookings">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <IoBookmarksOutline className='file_text_icon' />
                    </div>
                  </div>
                  <span className='fw-semibold d-block mb-1 text_blue_set'>Total Rejected Bookings </span>
                  <h3 className="card-title text-nowrap mb-1">{data.total_reject_booking}</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 col-md-12 col-6 mb-4">
            <Link to="/admin/bookings">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <IoBookmarksOutline className='file_text_icon' />
                    </div>
                  </div>
                  <span className='fw-semibold d-block mb-1 text_blue_set'>Today Bookings</span>
                  <h3 className="card-title text-nowrap mb-1">{data.this_day_booking}</h3>
                </div>
              </div>
            </Link>
          </div>


          <div className="col-lg-4 col-md-12 col-6 mb-4">
            <Link to="/admin/bookings">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 mb_4">
                      <IoBookmarksOutline className='file_text_icon' />
                    </div>
                  </div>
                  <span className='fw-semibold d-block mb-1 text_blue_set'>This Month  Bookings</span>
                  <h3 className="card-title text-nowrap mb-1">{data.this_month_booking}</h3>
                </div>
              </div>
            </Link>
          </div>

        </div>
      </div>
    </>
  )
}

export default Dashboard;
