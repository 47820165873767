import React, { useState } from "react";
import {
  apibaseURl,
  showSuccessToast,
  showErrorToast,
  fileupload,
  filebasepath,
} from "../../../Function";
import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DOMPurify from "dompurify";
import he from "he";

const AddService = () => {
  const [inputs, setInputs] = useState({
    title: "",
    descrip: "",
    status: "active",
    sort: "",
    service_featured: "",
    meta_keyword: "",
    meta_key_discription: "",
  

  });
  const [image, setImage] = useState("");
  const [headerimage, setHeaderimage] = useState("");
  const [progressCount, setprogressCount] = useState(0);
  const [progressCountheaderimage, setprogressCountheaderimage] = useState(0);
  const navigate = useNavigate();

  // image upload api in ==================================>
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${fileupload}addimage`, formData, {
        onUploadProgress: (data) => {
          const percentCompletedto = Math.round((data.loaded/data.total)*100);
          setprogressCount(percentCompletedto);
          if (percentCompletedto == 100) {
            setTimeout(()=>{
            setprogressCount(0);
          },4000)
          }
        },
      });
      if (response.status === 200 && response.data.status === true) {
        setImage(response.data.data.image);
        showSuccessToast(response?.data?.msg);
      }
    } catch (error) {
    
    }
  };

  //  service header image upload api in ================================== ================>
  const handleFileUploadheader = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${fileupload}addimage`, formData, {
        onUploadProgress: (data) => {
          const percentCompletedto = Math.round((data.loaded/data.total)*100);
          setprogressCountheaderimage(percentCompletedto);
          if(percentCompletedto == 100) {
          setTimeout(()=>{
            setprogressCountheaderimage(0)
          },4000)
          }
        },
      });

      if (response.status === 200 && response.data.status === true) {
        setHeaderimage(response.data.data.image);
        showSuccessToast(response?.data?.msg);
    
      }
    } catch (error) {
    
    }
  };
  // data insert in  api ==============>
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const servicedata = {
      title: inputs.title,
      image: image,
      header_image: headerimage,
      descrip: inputs.descrip,
      status: inputs.status,
      sort: inputs.sort,
      service_featured: inputs.service_featured,
      meta_keyword: inputs.meta_keyword,
      meta_key_discription: inputs.meta_key_discription,
    };
    try {
      const response = await axios.post(`${apibaseURl}addservice`, servicedata);
      if (response.status === 200) {
        if (response?.data?.status === true) {
          const encodedDescription = response.data.data.descrip;
          const decodedDescription = he.decode(encodedDescription);

          function htmlToPlainText(decodedDescription) {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = decodedDescription;
            return tempDiv.textContent || tempDiv.innerText || "";
          }
          const plainText = htmlToPlainText(decodedDescription);
          setInputs({ ...response.data.data.descrip, descrip: plainText });
          navigate("/admin/adminservice");
          showSuccessToast(response?.data?.msg);
        } else {
          if (response?.data?.error) {
            const errorMessages = getValidationErrors(response?.data?.error);
            showErrorToast(errorMessages);
          } else {
            showErrorToast("General Error", response?.data?.msg);
          }
        }
      }
    } catch (error) {
 
    }
  };

  const getValidationErrors = (errors) => {
    let errorMessages = [];
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorMessages.push(<div key={key}>{errors[key][0]}</div>);
      } else {
      }
    }

    const storeAllValidtionErr = <>{errorMessages}</>;
    return storeAllValidtionErr;
  };

  // image delte api call =================>
  const handledeleteimage = () => {
    setImage("");
  };
  const handledeleteimageheader = () => {
    setHeaderimage("");
  };
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="data_acrd_wrapper">
                <h5 className="card-header">Add Service</h5>
                <Link
                  to="/admin/adminservice"
                  className="btn btn-primary primary_ya"
                >
                  <span className="class_plus">
                    <IoMdArrowRoundBack />
                  </span>
                  Back
                </Link>
              </div>
              <div className="add_divider">
                {" "}
                <hr />
              </div>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Service Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Service Title"
                      name="title"
                      value={inputs.title}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md mb-3">
                    <small className="d-block">
                      <b>SERVICE FEATURED</b>
                    </small>
                    <div className="form-check form-check-inline mt-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="service_featured"
                        id="inlineRadio1"
                        value="Yes"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="service_featured"
                        id="inlineRadio2"
                        value="No"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        No
                      </label>
                    </div>
                  </div>



                  <div className="mb-3">
                    <label className="form-label">Service Sort</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Service Sort"
                      name="sort"
                      value={inputs.sort}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="formFileMultiple" className="form-label">
                      Service Image
                    </label>
                    <br />
                    {image ? (
                      <div className="image_wapre_div_for_data_image">
                        <img
                          src={filebasepath + image}
                          alt="Avatar"
                          className=""
                          width="50px"
                        />
                        <i
                          class="fa fa-times bar_second_image_top"
                          aria-hidden="true"
                          onClick={handledeleteimage}
                        ></i>
  
                        
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      className="form-control mt-1"
                      type="file"
                      id="formFileMultiple1"
                      name="serviceicon"
                      onChange={handleFileUpload}
                    />
                    {progressCount > 0 && (
                      <div class="progress mt-2">
                        <div
                          class="progress-bar progress-bar-striped bg-danger progress-bar-animated progress_bar_height_decode"
                          role="progressbar"
                          aria-valuenow={progressCount}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${progressCount}%` }}
                        >{`${progressCount}%`}</div>
                      </div>
                    )}
                  </div>

                  {/* <div className="mb-3">
                    <label htmlFor="formFileMultiple" className="form-label">
                      Service Header Image
                    </label>
                    <br />
                    {headerimage ? (
                      <div className="image_wapre_div_for_data_image">
                        <img
                          src={filebasepath + headerimage}
                          alt="Avatar"
                          className=""
                          width="200px"
                        />
                        <i
                          class="fa fa-times bar_second_image_top"
                          aria-hidden="true"
                          onClick={handledeleteimageheader}
                        ></i>
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      className="form-control mt-1"
                      type="file"
                      id="formFileMultiple2"
                      name="serviceheaderimg"
                      onChange={handleFileUploadheader}
                    />

                    {progressCountheaderimage > 0 && (
                      <div class="progress mt-2">
                        <div
                          class="progress-bar progress-bar-striped bg-danger progress-bar-animated progress_bar_height_decode"
                          role="progressbar"
                          aria-valuenow={progressCountheaderimage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${progressCountheaderimage}%` }}
                        >{`${progressCountheaderimage}%`}</div>
                      </div>
                    )}
                  </div> */}

                  <div className="mb-3">
                    <label className="form-label">Service Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={inputs.descrip}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        let clean = DOMPurify.sanitize(data);
                        setInputs({ ...inputs, descrip: clean });
                      }}
                      onReady={(editor) => {
                      
                      }}
                      onBlur={(event, editor) => {
                  
                      }}
                      onFocus={(event, editor) => {
                      
                      }}
                    />
                  </div>


                  <div className="mb-3">
                    <label className="form-label">Seo Mata Keyword</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Seo Mata Keyword"
                      name="meta_keyword"
                      value={inputs.meta_keyword}
                      onChange={handleChange}
                    />
                  </div>



                  <div className="mb-3">
                    <label className="form-label">Seo Mata Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Seo Mata Description"
                      name="meta_key_discription"
                      value={inputs.meta_key_discription}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlSelect1"
                      className="form-label"
                    >
                      Service Status
                    </label>
                    <select
                      className="form-select"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      name="status"
                      value={inputs.status}
                      onChange={handleChange}
                    >
                      <option>select Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>


                  <div className="mb-3 text-center">
                    <button type="submit" className="btn btn-primary">
                      submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddService;
