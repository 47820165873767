import React from 'react'
import { Link } from 'react-router-dom';
import 'animate.css';

const Contactinfo = ({ datasetting ,isContact}) => {

  return (
    <>
      <section id="findUs" className='find_us_to_contact_info'>
        <div className={`${isContact == true ? "container":""}`}>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 mb-3 mb-lg-4 text-center animate__animated animate__backInLeft">
              <h5 className="text-danger">FIND US</h5>
              <h2 className='sub_heding'>Access us easily</h2>
            </div>
            <div className="col-12">
              <div className="card card-span rounded-2 mb-3">
                <div className="row">
                  <div className={`${isContact == true ? "col-12 col-md-6 col-lg-6":"col-md-12 col-lg-12 "} d-flex order-2 order-lg-2`}>
                    <div className={`${isContact == true ? "ifrrame_for_contact_data_contact" :"ifrrame_for_contact_data_home"}`}>
                      <iframe
                        src={datasetting.Map}
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className={`${isContact == true ? "ifrrame_for_contact_data_contact" :"ifrrame_for_contact_data_home"}`}
                      />
                    </div>
                  </div>
                  <div className={`${isContact == true ? "col-12 col-md-6 col-lg-6":"col-md-12 col-lg-12 "} d-flex flex-center order-1 order-lg-2`}>
                    <div className="card-body py-4 contact_info_address_locate">
                      <h5 className='contact_info_heading_to'>Contact with us</h5>
                      <p className="text-700 my-4">
                        {" "}
                        <i className="fa fa-map-marker purple me-3" aria-hidden="true"></i>
                        <span>{datasetting.address}</span>
                      </p>
                      {datasetting.admin_phone ?
                        <p>
                          <i className="fa fa-phone purple  me-3" aria-hidden="true"></i>
                          <span className="text-700">
                            <Link className="phone_acaall anker_tocolor" to={`tel:${datasetting.admin_phone}`}>
                              {datasetting.admin_phone}
                            </Link>
                          </span>
                        </p>
                        : ""
                      }

                      {datasetting.admin_phone_two ?
                        <p>
                          <i className="fa fa-phone purple  me-3" aria-hidden="true"></i>
                          <span className="text-700">
                            <Link className="phone_acaall anker_tocolor" to={`tel:${datasetting.admin_phone_two}`}>
                              {datasetting.admin_phone_two}
                            </Link>
                          </span>
                        </p>
                        : ""
                      }

                      {datasetting.fax_number_one ?
                        <p>
                          <i className="fa fa-fax purple me-3" aria-hidden="true"></i>
                          <span className="text-700">
                            <Link className="phone_acaall anker_tocolor" to={`tel:${datasetting.fax_number_one}`}>
                              {datasetting.fax_number_one}
                            </Link>
                          </span>
                        </p>
                        : ""}


                      {datasetting.fax_number_two ?
                        <p>
                          <i className="fa fa-fax me-3" aria-hidden="true"></i>
                          <span className="text-700">
                            <Link className="phone_acaall anker_tocolor" to={`tel:${datasetting.fax_number_two}`} >
                              {datasetting.fax_number_two}
                            </Link>
                          </span>
                        </p>
                        : ""

                      }

                      {datasetting.admin_email ?
                        <p>
                          <i className="fa fa-envelope purple me-3" aria-hidden="true"></i>
                          <Link className="text-700 anker_tocolor" to={`mailto:${datasetting.admin_email}`}>
                            {datasetting.admin_email}
                          </Link>
                        </p>
                        : ""}


                      {datasetting.admin_email_two ?
                        <p>
                          <i className="fa fa-envelope purple me-3" aria-hidden="true"></i>
                          <Link className="text-700 anker_tocolor" to={`mailto:${datasetting.admin_email_two}`}>
                            {datasetting.admin_email_two}
                          </Link>
                        </p>
                        : ""}


                      {datasetting.web_link_one ?
                        <p>
                          <i class="fa fa-globe purple me-3" aria-hidden="true"></i>
                          <Link className="text-700 anker_tocolor" to={`http://${datasetting.web_link_one}`}>
                            {datasetting.web_link_one}
                          </Link>
                        </p>
                        : ""}

                      {datasetting.web_link_two ?
                        <p>
                          <i className="fa fa-globe purple me-3" aria-hidden="true"></i>
                          <Link className="text-700 anker_tocolor" to={`mailto:${datasetting.web_link_two}`}>
                            {datasetting.web_link_two}
                          </Link>
                        </p>
                        : ""}


                      <ul className="list-unstyled list-inline mt-4 icon_info_quick_call_us">
                        {datasetting.fb_icon && (
                          <li className="list-inline-item1">
                            <Link className="text-decoration-none" to={datasetting.fb_icon} target='_blank'>
                              <i className="fa fa-facebook-official fs-2" aria-hidden="true"></i>
                            </Link>
                          </li>
                        )}
                        {datasetting.ig_icon && (
                          <li className="list-inline-item1">
                            <Link className="text-decoration-none" to={datasetting.ig_icon} target='_blank'>
                              <i className="fa fa-instagram fs-2" aria-hidden="true"></i>
                            </Link>
                          </li>
                        )}

                        {datasetting.in_icon && (
                          <li className="list-inline-item1">
                            <Link className="text-decoration-none" to={datasetting.in_icon} target='_blank'>
                              <i className="fa fa-linkedin fs-2" aria-hidden="true"></i>
                            </Link>
                          </li>
                        )}

                        {datasetting.yt_icon && (
                          <li className="list-inline-item1">
                            <Link className="text-decoration-none" to={datasetting.yt_icon} target='_blank'>
                              <i className="fa fa-youtube-play fs-2" aria-hidden="true"></i>
                            </Link>
                          </li>
                        )}

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="text-center">
          <button className="btn btn-primary px-5" type="submit">
            <i className="fas fa-phone-alt me-2" />
            <Link className="text-light phone_acaall" to={`tel:${data.admin_phone}`}>
              Call us to delivery {data.admin_phone}
            </Link>
          </button>
        </div> */}
            </div>
          </div>
        </div>
        {/* end of .container*/}
      </section>

    </>
  )
}

export default Contactinfo;
