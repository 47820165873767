import React, { useEffect, useState } from 'react'
import HomeService from './HomeService'
import Contactinfo from './Contactinfo'
import Carousel from 'react-multi-carousel';
import { FaArrowRight } from "react-icons/fa";
import { filebasepath } from "../../../Function";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import headerimg from '../../../front/assest/img/gallery/home-01-slide-01.jpg'
import BookingInfoset from './BookingInfoset';
import Sticky from 'react-sticky-el';


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Home = ({ servicedata, datasetting, setFeaturedata, sliderdata, metadescription }) => {
  const [navHeight, setNavHeight] = useState(0);
  const [storematatag, setStorematatag] = useState("");

  // from submit process call here ==========================>
  // from submit process call here ==========================>


  useEffect(() => {
    const targetSlug = 'service';
    const targetObject = metadescription.find(item => item.slug === targetSlug);
    setStorematatag(targetObject)
  }, [metadescription])

  const customStyleObject = {
    backgroundColor: 'lightblue',
    boxShadow: '0 4px 2px -2px gray',
    padding: '10px',
  };

  useEffect(() => {
    const navElement = document.querySelector('.navbar');
    if (navElement) {
      setNavHeight(navElement.offsetHeight);
    }
  }, []);
  return (
    <>
      {storematatag &&
        <Helmet>
          <meta name="description" content={storematatag.meta_key_discription} />
          <meta name="keywords" content={storematatag.meta_keyword} />
        </Helmet>}



      <section className="py-xxl-10 pb-0 hero-section" id="home" style={{ marginTop: `${navHeight}px` }}>
        <div
          className="bg-holder bg-size"
          style={{
            backgroundImage: `url(${filebasepath + datasetting.Banner_image})`,
            backgroundPosition: "top center",
            backgroundSize: "cover"
          }} ></div>
        {/*/.bg-holder*/}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-xl-12 col-xxl-12 text-md-start text-center py-8 text-box slider_text_description">
              <Carousel responsive={responsive} autoPlay={true} infinite={true} showDots={false} autoPlaySpeed={3000}
                removeArrowOnDeviceType={["mobile", "tablet", ""]}
              >
                {sliderdata.map((item, index) => {
                  return (
                    <div>
                      <h1 className="fw-normal fs-6 fs-xxl-7 hero-inner-heading _carosel_home_to">
                        {item.title}{" "}
                      </h1>
                      <p className="fs-1 mb-5 para_carosel_home_to">
                        {item.discription}
                      </p>
     
                    </div>
                  )
                })}
                   </Carousel>
                     <Link className="btn btn-primary me-2 booking_now_check" to={datasetting.banner_link} role="button">
                       {datasetting.Banner_button_name}
                        <FaArrowRight className='aerow_right' />
                      </Link>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='row'>
           <div className='col-md-6 order-lg-2'>
              <BookingInfoset  HomePageCheck={true} datasetting={datasetting}/>
            </div>
            <div className='col-md-6 order-lg-1'>
              <HomeService
                isFeatured={true}
                servicedata={servicedata}
                setFeaturedata={setFeaturedata}
                service_top_rep={'service_top_rep'}
              />
              <Contactinfo datasetting={datasetting} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
