import React, { useEffect, useState } from 'react';
import Contactinfo from './home/Contactinfo';
import axios from 'axios';
import { apibaseURl, showSuccessToast, showErrorToast, fileupload } from "../../Function";
import enqueryimg from '../../front/assest/img/illustrations/callback.jpg'
import { Helmet } from 'react-helmet';

const Contact = ({datasetting,metadescription}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successms, setSuccessms] = useState("");
  const[storematatag,setStorematatag] = useState("");
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessms("")
    const servicedata = {
      name: inputs.name,
      email: inputs.email,
      message: inputs.message,
    };
    setErrors("")
    try {
      setIsLoading(true);
      const response = await axios.post(`${apibaseURl}addenquiry`, servicedata);
      if (response.status === 200) {
        if (response?.data?.status === true) {
          setSuccessms(response?.data?.msg)
          setInputs({
            name: '',
            email: '',
            message: ''
          });
          setTimeout(()=>{
            setSuccessms("")
          },5000)
        } else {
          if (response?.data?.error) {
            const errorMessages = getValidationErrors(response?.data?.error);
            setErrors(errorMessages);
          } else {
            showErrorToast('General Error', response?.data?.msg);
          }
        }
      }
    } catch (error) {
 
    }finally {
      setIsLoading(false); // Stop loading, regardless of the response
    }
  };

  const getValidationErrors = (errors) => {
    let errorMessages = {
      name: '',
      email: '',
      message: ''
    };
    
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorMessages[key] = errors[key].join(', ');
      }
    }
    return errorMessages;
  };

  useEffect(()=>{
   const targetSlug = 'contact';
   const targetObject = metadescription.find(item => item.slug === targetSlug);
   setStorematatag(targetObject)
  },[metadescription])

  return (
    <>

    {storematatag  &&
      <Helmet>
        <meta name="description" content={storematatag.meta_key_discription} />
        <meta name="keywords" content={storematatag.meta_keyword} />
      </Helmet>}
<section>
  <div className="container mt-5">
    <div className=" mb-6 text-center">
      <h5 className="text-danger pt-5">Get In Touch</h5>
      <h2 className='sub_heding'>Contact Us</h2>
    </div>
    <div className="row justify-content-center">
    
      <div className=" col-sm-12 col-md-6 col-lg-6 col-xl-6 order-2 order-lg-1 mt_bar_for_contact">

       {successms ? 
           <div class="alert alert-success" role="alert">
              {successms} 
            </div>
       :""}


      <form className="row contact_now_to_query" onSubmit={handleSubmit}>
          <div className="mb-2">
            <label className="form-label visually-hidden" htmlFor="inputName">
              Name
            </label>
            <input
              className="form-control form-quriar-control"
              id="inputName"
              type="text"
              placeholder="Name"
              name="name"
              value={inputs.name}
              onChange={handleChange}
            />
              <p className="text-danger">{errors.name}</p>
          </div>
          <div className="mb-2">
            <label className="form-label visually-hidden" htmlFor="inputEmail">
              Another label
            </label>
            <input
              className="form-control form-quriar-control"
              id="inputEmail"
              type="email"
              placeholder="Email"
              name="email"
              value={inputs.email}
              onChange={handleChange}
            />
              <p className="text-danger">{errors.email}</p>
          </div>
          <div className="mb-2">
            <label
              className="form-label visually-hidden">
              Message
            </label>
            <textarea
              className="form-control form-quriar-control border-400"
              id="validationTextarea"
              style={{ height: 150 }}
              name="message"
              value={inputs.message}
              placeholder="Message"
              onChange={handleChange}/>
                <p className="text-danger">{errors.message}</p>
          </div>
          <div className="d-grid">
            <button className="btn btn-primary booking_now_check" type="submit" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 order-1 order-lg-2">
        <div>
            <img src={enqueryimg} alt="..." width="100%" style={{borderRadius:"5px"}}/>
        </div>
       
      </div>
    </div>
  </div>

</section>



      <Contactinfo datasetting={datasetting} isContact={true}/>
    </>
  )
}

export default Contact;
