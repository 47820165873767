import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import aboutimg1 from '../../front/assest/img/gallery/about-us-img.jpg'




const About = ({ metadescription }) => {
  const [storematatag, setStorematatag] = useState("");
  useEffect(() => {
    const targetSlug = 'about';
    const targetObject = metadescription.find(item => item.slug === targetSlug);
    setStorematatag(targetObject)
  }, [metadescription])

  return (
    <>
      {storematatag &&
        <Helmet>
          <meta name="description" content={storematatag.meta_key_discription} />
          <meta name="keywords" content={storematatag.meta_keyword} />
        </Helmet>}


      <section className="py-7 about-us" container-xl="container-xl">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-md-8 col-lg-5 text-center mb-3">
             
              <h2 className="mt-3">About Us</h2>
            </div>
          </div>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-md-6 col-12 pt-4 px-md-2 px-lg-3">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src={aboutimg1}
                  alt="about-us"
                />
              </div>
            </div>
            <div className="col-md-6 col-12 pt-4 px-md-2 px-lg-3">
              <h5 className="inner-heading">About Our Company</h5>
              <h5 className="">
              We are a team of highly trained customer support and we are the 
              one stop for all your printer needs. 
              </h5>
              <ul className="order-list primary-color mrb-40">
                <li>We are committed to providing reliable support for all printer models.</li>
                <li>Our streamlined booking process ensures that you can schedule a technician in just a minute</li>
              </ul>
              <p className="mrb-40">
                We will register the complaint with the right authorized service center for your printer. Someone will contact you by phone same day to fix your printer remotely, otherwise the technician will visit your home same day to resolve your printer issues.
              </p>

              <p className="mrb-40">
              
                We are here to register your complaint to your nearest 
                Authorized service center to repair your printer online or by visiting your home.
                 Also it comes with an addition benefit, as we all know anything under the warrenty 
                 is fixed for free of cost but the company still charges the home visit charges. 
                 These home visit charges are zero for all who register their complaints through us.

                We are helping printer users since 2000 and we aim to expand worldwide in future.
              </p>
            </div>
          </div>
        </div>

      </section>
      {/* <section className="py-0 about-us about_bar_top_4" container-xl="container-xl">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-md-6 col-12 pt-4 px-md-2 px-lg-3 order-2 order-lg-1">
                <h5 className="inner-heading">Why Choose Us?</h5>
                <h2 className="about-us-heading">
                  We Deliver your goods Very Fast &amp; Secure
                </h2>
                <p className="mrb-40">
                  Distinctively exploit optimal alignments for intuitive. Quickly
                  coordinate business applications through revolutionary cataly
                  technologies rather than development optimal alignments for
                  intuitive business applications through.
                </p>
              </div>
              <div className="col-md-6 col-12 pt-4 px-md-2 px-lg-3 order-1 order-lg-2">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src={aboutimg2}
                    alt="about-us"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}
    </>
  )
}

export default About;
